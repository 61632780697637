<template>
  <div class="container container-top">
    <div class="pc">
      <div class="h5 main padding-top-44">1:1 문의등록</div>

      <div class="flex-between margin-top-24" v-if="!isLogin && !$route.query.id">
        <div class="flex-align">
          <img class="svg-primary" src="/static/icon/fi_alert-circle.svg" style="width:20px;height:20px"/>
          <div class="body3 sub" style="margin-left:6px">회원으로 이용하실 분은 로그인 해주세요.</div>
        </div>
        <div>
          <button class="button is-primary-light body4-medium"
                  style="width:120px;height:36px"
                  @click="clickLogin">로그인</button>
        </div>
      </div>
      <div class="lp-divider-sub5" :class="{'padding-top-24':isLogin, 'padding-top-8':!isLogin}"></div>
      <div class="padding-top-16"></div>
      <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
        <div class="flex margin-top-16">
          <div class="inquiry-reg-title subtitle5">
            <template v-if="form.field !== 'checkedVal'">
            {{ form.label }} {{ form.validate.required ? '*' : '' }}
            </template>
          </div>
          <div class="inquiry-reg-content">
            <!--<input-single-file :value.sync="form.file_ext"
                               class="margin-top-8"
                               @setValue="val=>form.value=val"
                               placeholder="예) 소개서, 기획안 와이어프레임 등등"
                               v-if="form.component_name==='input-single-file'"></input-single-file>-->
            <input-file v-if="form.component_name === 'input-file'"
                        :value.sync="form.value" :placeholder="form.placeholder"></input-file>
            <component :is="form.component_name"
                       :ref="form.field"
                       v-bind.sync="form" v-else></component>
          </div>
        </div>
      </div>
      <div class="flex-center" style="margin-top:80px" v-if="!$route.query.id">
        <div class="flex-align">
          <button class="button body2-medium" style="width:242px;height:48px" @click="$router.back()">취소</button>
          <button class="button is-primary body2-medium" style="width:242px;height:48px;margin-left:16px" @click="saveData">등록하기</button>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div style="padding-bottom:50px">
        <div class="h8 main padding-top-24">1:1 문의등록</div>
        <div class="flex-align margin-top-24" v-if="!isLogin && !$route.query.id" @click="clickLogin">
          <img class="svg-primary" src="/static/icon/fi_alert-circle.svg" style="width:16px;height:16px"/>
          <div class="body5 sub text-underline" style="margin-left:6px">회원으로 이용하실 분은 로그인 해주세요.</div>
        </div>

        <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
          <div class="margin-top-12">
            <div class="subtitle7">
              <template v-if="form.field !== 'checkedVal'">
                {{ form.label }} {{ form.validate.required ? '*' : '' }}
              </template>
            </div>
            <!--<input-single-file :value.sync="form.file_ext"
                               class="margin-top-8"
                               @setValue="val=>form.value=val"
                               placeholder="예) 소개서, 기획안 와이어프레임 등등"
                               v-if="form.component_name==='input-single-file'"></input-single-file>-->
            <input-file v-if="form.component_name === 'input-file'"
                        :value.sync="form.value" :placeholder="form.placeholder"></input-file>
            <component :is="form.component_name"
                       class="margin-top-8"
                       v-bind.sync="form" v-else></component>
          </div>
        </div>
      </div>

      <!--<button class="button is-primary margin-top-40 body2-bold"
              style="width:100%;height:52px;"
              @click="saveData"
              v-if="!$route.query.id">등록하기
      </button>-->
      <btn-floating text="등록하기" :height="52" @clicked="saveData"></btn-floating>

    </div>
  </div>
</template>
<script>
  import LpDropDown from "../LpDropDown";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import LpCheckBool from "../module/LpCheckBool";
  import LpRadio from "../module/LpRadio";
  import InputSingleFile from "../module/InputSingleFile";
  import LpText from "../component/LpText";
  import LpTextArea from "../component/LpTextArea";
  import LpNumberUnit from "../component/LpNumberUnit";
  import FormMixin from "../../mixins/FormMixin";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import { SweetModal } from 'sweet-modal-vue';
  import BtnFloating from "../component/BtnFloating";
  import InputFile from "../module/InputFile";
  export default {
    name: "PersonalInquiryReg",
    components: {
      InputFile,
      BtnFloating,
      LpTextArea, LpText, InputSingleFile, LpCheckBool, LpRadio, LpNumberUnit, LpCheckBoxArray, SweetModal, LpDropDown},
    mixins: [
      UserAPIMixin,
      FormMixin
    ],
    async created() {
      await this.getInquiryCategory();
      await this.setForm();

      if(this.$route.query.type_id) {
        let is_cat = this.findItem(this.catArr, 'value', parseInt(this.$route.query.type_id));
        if(is_cat) this.formData[0].value = parseInt(this.$route.query.type_id);
      }
    },
    data() {
      return {
        modalWidth: 560,
        formData: [],
        catArr: [],
        checkedVal: false,
        /*value: {
          type_id: "",
          title: "",
          content: "",
          name: "",
          phone: "",
          email: "",
          imgs: ""
        }*/
      }
    },
    computed: {
      selectedTypeName() {
        let name = '';
        let item = this.findItem(this.catArr, 'value', this.value.type_id);
        if(item) {
          name = item.label;
        }
        return name;
      }
    },
    methods: {
      clickLogin() {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.$router.push('/signin');
      },
      setForm() {
        let checkFontSize = '15px';
        let width = '270px';
        if(window.innerWidth<500) {
          checkFontSize = '12px';
          width = '100%';
        }
        this.formData = [
          {
            label: '문의 유형',
            placeholder: '문의 유형을 선택해주세요.',
            maxLength: 30,
            field: 'type_id',
            type: 'dropdown',
            value: '',
            component_name: 'lp-drop-down',
            errorMsg: '',
            options: this.catArr,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '제목',
            placeholder: '제목을 입력해주세요.',
            maxLength: 30,
            field: 'title',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '내용',
            placeholder: '내용을 입력해주세요.',
            maxLength: 10000,
            field: 'content',
            type: 'text',
            value: '',
            component_name: 'lp-text-area',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 10000,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '첨부 파일',
            placeholder: '',
            field: 'files',
            value: [],
            component_name: 'input-file',
            validate: {
              valid_required: false,
              required: false
            }
          },
          {
            label: '성함',
            placeholder: '성함을 입력해주세요.',
            maxLength: 30,
            field: 'name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            width: width,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '연락처',
            placeholder: '연락처를 입력해주세요.',
            maxLength: 30,
            field: 'phone',
            type: 'phone',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            width: width,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '이메일',
            placeholder: '이메일을 입력해주세요.',
            maxLength: 30,
            field: 'email',
            type: 'email',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            width: width,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '이용약관 및 개인정보처리방침에 동의하기',
            items: [
              {
                label: '이용약관 및 개인정보처리방침에 동의하기',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'privacy',
                labelStyle: {
                  color: '#828282',
                  fontSize: checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true,
              required: true
            },
          }
        ];
        let name = '';
        let email = '';
        let phone = '';
        if (this.user.user_id) {
          let user = this.cloneItem(this.$store.getters.user)
          name = user.name;
          email = user.email;
          phone = user.phone;
          this.formData.forEach(item=>{
            if(item.field==='name') {
              item.value = name;
            } else if(item.field==='email') {
              item.value = email;
            } else if(item.field==='phone') {
              item.value = phone;
            }
          })
        }
      },
      async getInquiryCategory() {
        await this.$axios.get(`public/inquiry/personal/category`).then(res=>{
          this.catArr = res.data;
        })
      },
      getData() {
        if(this.$route.query.id) {
          this.request.user.get(`inquiry/personal/${this.$route.query.id}`).then(res=> {
            this.value = res.data;
            this.value.type_id = parseInt(this.value.type_id);
          })
        }
      },
      saveData() {
        let result = this.getParamData(this.formData);
        if(result.enable) {
          let params = this.cloneItem(result.params);
          params.channel = '플랫폼파이';
          if (this.isLogin) {
            params.user_id = this.user.user_id;
          }
          let url = 'public/inquiry/personal';
          // let url = 'http://localhost:8001/public/inquiry/personal';
          this.$axios.post(url, params).then(res=>{
            if(res.status===200) {
              this.routeGa('', '1:1문의 등록', '등록완료', '응답');
              this.toast('1:1문의등록이 완료되었습니다.');
              if(this.isLogin) {
                this.$router.replace('/inquiry')
              } else {
                this.$router.replace('/home')
              }
            }
          }).catch(err => {
            if (err.response.data.message) {
              this.toast(err.response.data.message);
            } else if (err.response.data.detail) {
              this.toast(err.response.data.detail);
            } else {
              this.toast('오류가 발생하였습니다. 관리자에게 문의주세요.');
            }
          });
        }
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .pc .inquiry-reg-title
    width 30%

  .pc .inquiry-reg-content
    width 70%
</style>
