<template>
  <div class="lp-check-bool-container">
    <div :class="{ 'checked-box' : !!isChecked, 'not-checked-box' : !isChecked }"
         :style="checkStyle"
         @click.stop.prevent="clickCheck">
      <i v-if="!!isChecked" class="material-icons checked-icon" @click.stop.prevent="clickCheck">check</i>
    </div>
    <div @click.stop.prevent="clickCheck" style="flex:1" :style="labelWrapStyle">
      <span v-for="(item, idx) in items" :key="`item-${idx}`"
            :style="labelStyle(item)"
            @click="clickLink(item)">{{ item.label }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'lp-check-box-array',
    props: {
      value: {
        type: Boolean
      },
      items: {
        type: Array,
        default: function() {
          return []
        }
      },
      labelWrapCustomStyle: {
        type: Object,
        required: false
      }
    },
    created() {
      this.isChecked = this.value;
    },
    data() {
      return {
        isChecked: false
      }
    },
    computed: {
      labelWrapStyle() {
        let style = {
          textAlign: 'left'
        }
        if(this.labelWrapCustomStyle) {
          style = this.labelWrapCustomStyle;
        }
        return style;
      },
      checkStyle() {
        let style = {}
        if(this.label) {
          style.marginRight = 8;
        }
        else style.marginRight = 0;

        return style
      }
    },
    methods: {
      clickLink(item) {
        if(item.link) window.open(item.link, '_blank');
      },
      labelStyle(item) {
        let style = {
          fontSize: '0.9rem',
          lineHeight: '17px',
          color: '#555'
        };
        if(item.labelStyle) {
          style = item.labelStyle;
        }
        return style;
      },
      clickCheck() {
        if ( this.isChecked === false ) {
          this.isChecked = true;
        }
        else if ( this.isChecked === true ) {
          this.isChecked = false;
        }
        this.$emit('update:value', this.isChecked);
        this.$emit('onCheck', this.isChecked);
        this.$emit('dataCheck', {id:this.label, value:this.isChecked});
      }
    },
    watch: {
      value(n) {
        this.isChecked = n;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .lp-check-bool-container
    /*display inline-flex*/
    display flex
    align-items center
    cursor pointer

  .checked-box
    position relative
    width 16px
    height 16px
    border 2px solid #ff6600
    border-radius 3px
    margin-right 8px !important
    text-align center

  .not-checked-box
    position relative
    width 16px
    height 16px
    border 2px solid #ff6600
    border-radius 3px
    margin-right 8px !important
    text-align center


  .checked-box
    background-color #ff6600


  .not-checked-box
    background-color #fff


  .checked-icon
    color #fff
    font-size 0.9rem
    font-weight bold
    position absolute
    left 0
    top 0


</style>